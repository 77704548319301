<template>
  <span>
    <span class="btn btn-edit" qid="edit-tp-modal" @click="showEditTPModal(object)">
      <font-awesome-icon icon="pen"/>
    </span>

    <!-- Edit third party modal template -->
    <b-modal ref="edit-third-party" hide-footer>
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('maintenance.third_parties.update.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-tp-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="editTPFormSubmit" qid="edit-tp-form">
        <div class="form-container">
          <h5 class="mb-3">
            <label>{{ $t('maintenance.third_parties.create.name_section_label') }}</label>
          </h5>

          <b-form-group>
            <label qid="create-tp-name-label">
              {{ $t('maintenance.third_parties.create.name_label') }}
            </label>
            <span class="text-red">*</span>
            <b-form-input
              qid="create-tp-form-name"
              type="text"
              v-model="tpForm.name"
              required
              :maxLength="255"
              :placeholder="$t('maintenance.third_parties.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-tp-contact-label">
              {{ $t('maintenance.third_parties.create.contract_reference_label') }}
            </label>
            <b-form-input
              qid="create-tp-form-contact"
              type="text"
              v-model="tpForm.reference"
              :maxLength="255"
              :placeholder="$t('maintenance.third_parties.create.contract_reference_placeholder')"
            ></b-form-input>
          </b-form-group>

          <h5 class="mb-2 mt-4">
            <label>{{ $t('maintenance.third_parties.create.ownership_section_label') }}</label>
          </h5>

          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('processes.create.owners_label') }}
            </label>
            <multiselect
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                :taggable="false"
                track-by="slug"
                :placeholder="$t('processes.create.owners_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @remove="removeUserFromDropdown"
                @search-change="loadUsers"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span></span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
            <div class="form-control auto-height mt-1" v-if="selectedUsers && selectedUsers.length">
              <div v-for="(user, index) in selectedUsers" class="mt-1 mb-1">
                <div class="row">
                  <div class="col-sm-1">
                    <font-awesome-icon
                      icon="star"
                      :class="{'color-yellow': user.owner, 'text-light': !user.owner}"
                      class="cursor-pointer"
                      @click="setOwner(index)"
                    />
                  </div>
                  <div class="col-sm-10">
                    <Avatar :object="user" size="xs" :noOwner="true"></Avatar>
                    <span class="ml-2">{{ user.name }}</span>
                  </div>
                  <div class="col-sm-1">
                    <font-awesome-icon
                      icon="times"
                      class="cursor-pointer"
                      @click="removeUser(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span class="text-light">
              * {{ $t('maintenance.third_parties.create.owners_tip') }}
            </span>
          </b-form-group>

          <h5 class="mb-3">
            <label>{{ $t('maintenance.third_parties.create.groups_section_label') }}</label>
          </h5>

          <b-form-group>
            <label qid="create-ac-pg-label">
              {{ $t('maintenance.third_parties.create.vendor_label') }}
            </label>
            <span class="text-red">*</span>
            <div class="row">
              <div class="col-sm-7">
                <multiselect
                    v-model="selectedVendors"
                    :options="vendorItems"
                    :multiple="true"
                    :taggable="false"
                    track-by="slug"
                    :placeholder="$t('maintenance.third_parties.create.vendor_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    @search-change="loadVendors"
                    qid="create-p-users-select"
                >
                  <template slot="tag" slot-scope="props">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-1">
                      {{ props.option.name }}
                    </span>
                    <font-awesome-icon
                      icon="times"
                      class="cursor-pointer mr-2"
                      @click="removeVendor(props.option)"
                    />
                  </template>
                  <template slot="option" slot-scope="props">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-1">
                      {{ props.option.name }}
                    </span>
                  </template>
                </multiselect>
              </div>
              <div class="col-sm-5">
                <VendorCreateForm @reloadContent="updateVendors" v-if="isGranted(permissions.THIRD_PARTY_CREATE)"></VendorCreateForm>
              </div>
            </div>
          </b-form-group>

          <b-form-group :class="{'multiselect--show-input': typeInput.length}">
            <label qid="edit-tp-type-label">
              {{ $t('maintenance.third_parties.create.type_label') }}
            </label>
            <multiselect
                v-model="selectedType"
                :options="types"
                :placeholder="$t('maintenance.third_parties.create.type_placeholder')"
                label="type"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                track-by="type"
                :preserveSearch="true"
                @search-change="loadTypes"
                qid="edit-tp-form-type"
            >
              <template slot="noResult" slot-scope="props">
                {{ $t('maintenance.third_parties.create.type_no_results') }}
              </template>
            </multiselect>
          </b-form-group>

          <b-form-group>
            <label qid="create-do-tags-label">
              {{ $t('processes.create.tags_label') }}
            </label>
            <multiselect
                v-model="selectedTags"
                :options="tags"
                :multiple="true"
                :taggable="true"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadTags"
                track-by="name_translated"
                :placeholder="$t('processes.create.tags_placeholder')"
                label="name_translated"
                qid="create-do-tags-select"
                @tag="addTag"
            ></multiselect>
          </b-form-group>

          <h5 class="mb-3 mt-4">
            <label>{{ $t('maintenance.third_parties.create.features_section_label') }}</label>
          </h5>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.contract_measures_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.contract_measures"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.data_privacy_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.data_privacy_feature"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.risk_managements_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.risk_management_feature"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.projects_and_assessments_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.project_and_assessment"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.relations_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.relations"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.validation_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.validation_activation"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditTPModal"
                    qid="edit-tp-cancel-button"
          >
            {{ $t('maintenance.third_parties.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="edit-tp-submit-button"
                    v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], thirdParty) && (!getClient.parent || (getClient.parent && thirdParty.partition))"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('maintenance.third_parties.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </span>

</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import Validations from '@/views/Validations/Validations.vue';
import ActivitiesTable from '@/views/ThirdParty/ActivitiesTable.vue';
import SystemsTable from '@/views/ThirdParty/SystemsTable.vue';
import DataProcessorAgreement from '@/views/ThirdParty/DataProcessorAgreement.vue';
import TransfersDisclosures from '@/views/ThirdParty/TransfersDisclosures.vue';
import Inspection from '@/views/ThirdParty/Inspection.vue';
import DataViewModal from '@/views/ThirdParty/DataViewModal.vue';
import Avatar from '@/components/Avatar.vue';
import ConfirmRemoveAttachment from '@/components/ConfirmRemoveAttachment.vue';
import DataObjectActivitiesModal from '@/views/ThirdParty/DataObjectActivitiesModal.vue';
import ThirdPartyHistory from '@/views/ThirdParty/ThirdPartyHistory.vue';
import RiskScenariosTable from '@/views/ThirdParty/RiskScenariosTable.vue';
import ShowMoreModal from '@/components/ShowMoreModal.vue';
import AssessmentsTable from '@/views/ThirdParty/AssessmentsTable.vue';
import UpdateSingleTaskModal from '@/views/Tasks/UpdateSingleTaskModal.vue';
import VendorCreateForm from '@/views/ThirdParty/VendorCreateForm.vue';
import VendorViewModal from '@/views/ThirdParty/VendorViewModal.vue';

  export default {
    name: 'SystemEditForm',
    data: function() {
      return {
        thirdParty: {},
        showInput: false,
        showSingleLinkLabel: true,
        inputLink: '',
        showFullDescription: false,
        showFullContact: false,
        itemToDelete: {},
        selectedType: [],
        tpForm: {
          name: '',
          description: '',
          reference: '',
          type: [],
          client:'',
          contract_measures: 1,
          data_privacy_feature: 1,
          risk_management_feature: 1,
          project_and_assessment: 1,
          relations: 1,
          validation_activation: 1,
          links: [],
          vendors: [],
          tags: []
        },
        error: '',
        countries: [],
        selectedCountries: [],
        inspectionItems: [
          { name: this.$t('maintenance.third_parties.inspection.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.inspection.external_audit'), value: 'External audit' },
          { name: this.$t('maintenance.third_parties.inspection.site_visit'), value: 'Site visit' },
          { name: this.$t('maintenance.third_parties.inspection.third-party_self-audit'), value: 'Third-party self-audit' }
        ],
        dpaItems: [
          { name: this.$t('maintenance.third_parties.data_processor_agreement.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.data_processor_agreement.yes'), value: 'Yes' },
          { name: this.$t('maintenance.third_parties.data_processor_agreement.n/a'), value: 'N/A' }
        ],
        dpaContractTypes: [
          { name: this.$t('maintenance.third_parties.dpa_contract_type.contract_model_clause'), value: 'Contract model clause' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.adequacy_decision'), value: 'Adequacy decision' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
        ],
        transfersDisclosuresThirdCountries : [
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.yes'), value: 'Yes' },
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.n/a'), value: 'N/A' }
        ],
        thirdCountryContractTypes: [
          { name: this.$t('maintenance.third_parties.third_country_contract_type.contract_model_clause'), value: 'Contract model clause' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.adequacy_decision'), value: 'Adequacy decision' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
        ],
        types: [],
        typeInput: '',
        dataObjects: [],
        selectedDataObjects: [],
        buttonDisabled: false,
        showDescriptionInput: false,
        processEditDescription: '',
        showContactInput: false,
        processEditContact: '',
        selectedFile: {},
        selectedLink: {},
        showDeleteAttachmentModal: false,
        showDeleteLinkModal: false,
        tabIndex: 0,
        myGuides: [],
        selectedTags: [],
        tags: [],
        users: [],
        newTags: [],
        DOTableItems: [],
        DOLoading: false,
        selectedDO: [],
        selectedUsers: [],
        selectedDataObject: {},
        viewDataObjectActivitiesModal: false,
        selectedDataObjectActivities: [],
        showHistoryTab: false,
        showRiskTab: false,
        showDataObjectDescription: false,
        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,
        keywordValue: '',
        selectedSort: '',
        selectedDirection: '',
        myTasks: [],
        selectedItem: {},
        showTagsModal: false,
        viewDataModal: false,
        showAssessmentsTab: false,
        checklistOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0},
        ],
        selectedVendors: [],
        vendorItems: [],
        vendorToView: {},
        viewVendorModal: false
      }
    },
    props: {
      object: {
        type: Object,
        required: true
      },
      edit: {
        type: Boolean,
      }
    },
    components: {
      Validations,
      Avatar,
      ActivitiesTable,
      SystemsTable,
      ConfirmRemoveAttachment,
      DataProcessorAgreement,
      TransfersDisclosures,
      Inspection,
      DataObjectActivitiesModal,
      ThirdPartyHistory,
      RiskScenariosTable,
      ShowMoreModal,
      DataViewModal,
      AssessmentsTable,
      UpdateSingleTaskModal,
      VendorCreateForm,
      VendorViewModal
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadUsers();
        this.loadTags();
      },
      object: function (value) {
        let self = this;
        if (value && value.id) {
          if (this.editParam && !this.modalShown) {
            setTimeout(function(){
              self.showEditPModal(self.object)
              self.modalShown = true;
            }, 500);
          }
        }
      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadDO()
      }, 400),
      reloadAll() {
        this.loadContent()
        this.loadTasks('update_basic_info');
      },
      openTagsModal(object) {
        this.selectedItem = object
        this.showTagsModal = true
      },
      closeTagsModal() {
        this.selectedItem = {}
        this.showTagsModal = false
      },
      handleCloseViewDataModal () {
        this.viewDataModal = false;
      },
      openViewDataModal(object) {
        this.selectedDataObject = object
        this.viewDataModal = true
      },
      openViewVendorModal(object) {
        this.vendorToView = object
        this.viewVendorModal = true
      },
      handleCloseViewVendorModal () {
        this.vendorToView = []
        this.viewVendorModal = false;
      },
      getReducedTags(tags) {
        let reducedTags = []
        if (tags.length > 3) {
          reducedTags = _.cloneDeep(tags);
          reducedTags = reducedTags.slice(0, 3);
        } else {
          reducedTags = tags;
        }
        return reducedTags;
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadDO();
      },
      pageChanged(page) {
        this.loadDO(page)
      },
      perPageChanged(perPage) {
        let self = this;
        // Load content when page changed listener is triggered
        this.loadDO(1, perPage.value)
        self.selectedPerPage = perPage.value
      },
      perPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadDO(1, self.selectedPerPage)
      },
      goToList() {
        this.$router.push('/third-party')
      },
      historyTabClicked() {
        this.showAssessmentsTab = false;
        this.showHistoryTab = true;
        this.showRiskTab = false;
      },
      riskTabClicked() {
        this.showAssessmentsTab = false;
        this.showRiskTab = true;
        this.showHistoryTab = false;
      },
      assessmentsTabClicked() {
        this.showAssessmentsTab = true;
        this.showHistoryTab = false;
        this.showRiskTab = false;
      },
      resetHistoryTab() {
        this.showHistoryTab = false;
        this.showRiskTab = false;
      },
      openViewDataObjectActivitiesModal(object){
        this.selectedDataObject = object
        this.viewDataObjectActivitiesModal = true
      },
      handleCloseViewDataObjectActivitiesModal () {
        this.selectedDataObject = []
        this.viewDataObjectActivitiesModal = false;
      },
      getTask(code) {
        let existingTask = _.find(this.myTasks, function(object) {
          return object.code == code
        });
        return existingTask
      },
      openToolbar(object) {
        this.$store.commit('storeTaskToolbarState', true);
        this.$store.commit('storeActiveTask', object);
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Third party' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Third party',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      openHelpCenter(object) {
        let topElement = document.getElementById("app");
        if (topElement.classList.contains("guide-collapsed")) {
          topElement.classList.toggle("guide-collapsed");
        }
        if (!topElement.classList.contains("activity-collapsed")) {
          topElement.classList.toggle("activity-collapsed");
        }

        this.$store.commit('storeHelpCenterState', true);
        this.$store.commit('storeActiveGuideScope', object);
      },
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeVendor(item) {
        let selectedItem = item
        let index = _.findIndex(this.selectedVendors, function(object) { return object.id == item.id; });
        this.$set(this.selectedVendors, index, selectedItem)
        // Remove item from selected users array
        this.selectedVendors.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      showDescriptionInputFunction() {
        this.showDescriptionInput = true;
        this.processEditDescription = this.thirdParty.description;
      },
      formDescriptionSubmit() {
        let self = this;
        piincHttp.put('third-parties/' + this.thirdParty.slug, {
          description: this.processEditDescription
        }).then(function(response) {
          self.thirdParty = response.data;
          self.showDescriptionInput = false;
          self.$toastr('success', self.$t('maintenance.third_parties.update.success'))
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      cancelDescriptionForm() {
        this.showDescriptionInput = false;
        this.processEditDescription = this.thirdParty.description;
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      resetFile() {
        let imagefile = document.querySelector('#file');
        imagefile.value = '';
      },
      openHelpCenter(object) {
        let topElement = document.getElementById("app");
        if (topElement.classList.contains("guide-collapsed")) {
          topElement.classList.toggle("guide-collapsed");
        }
        if (!topElement.classList.contains("activity-collapsed")) {
          topElement.classList.toggle("activity-collapsed");
        }

        this.$store.commit('storeHelpCenterState', true);
        this.$store.commit('storeActiveGuideScope', object);
      },
      updateValidation(validation) {
        // Event emmited from validation component, update active validation
        this.thirdParty.validation = validation
      },
      openDeleteAttachmentModal(object) {
        this.selectedFile = object
        this.showDeleteAttachmentModal = true
      },
      closeDeleteAttachmentModal () {
        this.selectedFile = {}
        this.showDeleteAttachmentModal = false;
        // Remove last item in breadcrumbs, because it will be added again on load content
        this.loadContent();
      },
      openDeleteLinkModal(object) {
        this.selectedLink = object
        this.showDeleteLinkModal = true
      },
      closeDeleteLinkModal () {
        this.selectedLink = {}
        this.showDeleteLinkModal = false;
        // Remove last item in breadcrumbs, because it will be added again on load content
        this.loadContent();
      },
      handleLink() {
        let self = this;
        let formData = new FormData();

        if (!this.inputLink.startsWith('http')) {
          this.inputLink = 'http://' + this.inputLink
        }

        formData.append("url", this.inputLink);
        formData.append("model_type", this.thirdParty.who_am_i);
        formData.append("model_id", this.thirdParty.id);

        piincHttp.post('links', formData).then(function() {
          // Remove last item in breadcrumbs, because it will be added again on load content
          self.$toastr('success', self.$t('maintenance.third_parties.link_add_success'))
          self.loadContent()
          self.inputLink = ''
          self.showInput = false
        }, function() {})
      },
      downloadMedia(object){
        piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.download = object.file_name;

          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        }, function() {});
      },
      onFileChange(file) {
        let files = file.target.files;
        let self = this;
        let formData = new FormData();
        let sizeError = false;

        for (var i = 0; i < files.length; i++) {
          // Equal to 10 MB
          if (files[i].size > 10485760) {
            sizeError = true;
          }
          formData.append('document[]', files[i]);
        }

        formData.append("name", "documents");
        formData.append("model_type", this.thirdParty.who_am_i);
        formData.append("model_id", this.thirdParty.id);

        if (sizeError) {
          this.error = this.$t('system.file_size')
        } else {
          piincHttp.post('documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(function() {
            // Remove last item in breadcrumbs, because it will be added again on load content
            self.$toastr('success', self.$t('maintenance.third_parties.media_add_success'))
            self.error = ''
            self.loadContent()
          }, function(error) {
            self.error = error.data.message;
            if (error.data.errors['document.0']) {
              self.error += ' ' + error.data.errors['document.0'][0]
            }
          })
        }
      },
      getUserAvatar(user){
        if (user && user.avatar) {
          return user.avatar;
        } else {
          return require('@/assets/avatar.png');
        }
      },
      showConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-third-party'].show()
      },
      hideConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-third-party'].hide()
      },
      confirmDeleteTP(object) {
        let self = this;
        piincHttp.delete('third-parties/' + object.slug).then(function() {
          self.hideConfirmDeleteModal();
          self.goToList();
          self.$store.commit('storeUpdateTasks', true);
          self.$store.commit('storeActiveTask', {});
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('maintenance.third_parties.delete.success'))
        }, function() {});
      },
      showEditTPModal(object) {
        this.loadTypes();
        this.loadTags();
        this.loadVendors()
        let self = this;

        if (object.users && object.users.length) {
          self.selectedUsers = [];
          _.forEach(object.users, function (user) {
            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedUsers.push(matchedUser)
          })
        }

        if (object.tags_translated && object.tags_translated.length) {
          this.selectedTags = _.cloneDeep(object.tags_translated);
        }

        if (object.vendors && object.vendors.length) {
          this.selectedVendors = _.cloneDeep(object.vendors);
        }


        if (object.type) {
          this.selectedType = []
          _.forEach(this.types, function(item) {
            if (item.type == object.type) {
              self.selectedType = item;
            }
          });
        }

        this.error = ''
        this.tpForm.slug = object.slug;
        this.tpForm.name = object.name;
        this.tpForm.description = object.description;
        this.tpForm.reference = object.reference;
        this.tpForm.contract_measures = object.contract_measures;
        this.tpForm.data_privacy_feature = object.data_privacy_feature;
        this.tpForm.risk_management_feature = object.risk_management_feature;
        this.tpForm.project_and_assessment = object.project_and_assessment;
        this.tpForm.relations = object.relations;
        this.tpForm.validation_activation = object.validation_activation;

        this.$refs['edit-third-party'].show()
      },
      hideEditTPModal() {
        this.typeInput = '';
        this.selectedUsers = []
        this.selectedTags = []
        this.selectedVendors = []
        this.selectedType = []
        this.error = ''
        this.$refs['edit-third-party'].hide()
      },
      editTPFormSubmit(evt) {
        this.buttonDisabled = true;
        var moment = require('moment');
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let selectedOwner = false;
        evt.preventDefault();

        this.tpForm.client = this.getClient.slug;

        this.tpForm.users = [];
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            this.tpForm.users.push(this.selectedUsers[i].id);
            if (this.selectedUsers[i].owner) {
              selectedOwner = true;
              this.tpForm.owner = this.selectedUsers[i].id;
            }
          }
        } else {
          selectedOwner = false;
        }

        this.tpForm.tags = [];
        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            this.tpForm.tags.push(this.selectedTags[i].name_translated);
          }
        }

        this.tpForm.vendors = [];
        if (this.selectedVendors && this.selectedVendors.length) {
          for (var i = 0; i < this.selectedVendors.length; i++) {
            this.tpForm.vendors.push(this.selectedVendors[i].id);
          }
        }

        if (this.typeInput && this.typeInput.length) {
          this.tpForm.type = this.typeInput
        } else {
          if (this.selectedType && this.selectedType.type) {
            this.tpForm.type = this.selectedType.type
          } else {
            this.tpForm.type = '';
          }
        }

        if (this.selectedVendors && this.selectedVendors.length) {
          piincHttp.put('third-parties/' + this.tpForm.slug, this.tpForm).then(function(response) {
            self.hideEditTPModal();
            self.loadTasks('update_basic_info');
            self.buttonDisabled = false;
            self.$emit('reloadContent', response.data);
            self.loadTypes();
            self.$toastr('success', self.$t('maintenance.third_parties.update.success'))
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = ''
          if (!this.selectedVendors || (this.selectedVendors && !this.selectedVendors.length)) {
            this.error +=  ' ' + this.$t('maintenance.third_parties.create.selected_vendors_error_message')
          }
          this.buttonDisabled = false;
        }
      },
      loadCountries() {
        let self = this;
        piincHttp.get('countries').then(function(response) {
          self.countries = response.data
        }, function() {});
      },
      loadTypes(query) {
        this.typeInput = query ? query : '';
        let self = this;
        piincHttp.get('/third-parties/types', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.types = response.data
          if (self.types && !self.types.length && !query) {
            self.types.push({type: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      confirmDeleteDO(object) {
        let self = this;
        piincHttp.delete('third-parties/' + this.thirdParty.slug + '/data-objects/' + object.id).then(function() {
          self.hideDOConfirmDeleteModal();
          self.loadDO();
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('systems.data_objects.delete.success'))
        }, function() {});
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.tags = response.data
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      updateVendors(object) {
        this.loadVendors()
      },
      loadVendors(searchQuery) {
        let self = this;
        piincHttp.get('vendors', { params:
          {
            client: this.getClient.slug,
            search: searchQuery !== '' ? searchQuery : undefined,
            parent: this.getClient.parent ? 1 : undefined,
            per_page: 100
          }
        }).then(function(response) {
          self.vendorItems = response.data.items
          if (self.vendorItems && !self.vendorItems.length) {
            self.vendorItems.push({category: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
      loadContent() {
        let self = this;
        piincHttp.get('third-parties/' + this.thirdParty.slug, { params:
          {
            client: this.getClient.slug,
          }
        }).then(function(response) {
          self.thirdParty = response.data;
        }, function() {});
      },
      loadTasks(code) {
        let self = this;
        if (this.getClient.projectModule) {
          piincHttp.get('projects/me/tasks', { params:
            {
              per_page: 1,
              status: 'pending',
              model_type: this.thirdParty.who_am_i,
              model_id: this.thirdParty.id,
              code: code,
              client: this.getClient.slug
            }
          }).then(function(response) {
            self.myTasks = [];
            if (response.data.items.length) {
              self.myTasks.push(response.data.items[0])
            }
          }, function() {});
        }
      }
    },
    created () {
      this.editParam = this.edit;
      this.loadTypes()
      let self = this;
      if (this.object && this.object.id) {
        if (this.editParam && !this.modalShown) {
          setTimeout(function(){
            self.showEditPModal(self.object)
            self.modalShown = true;
          }, 500);
        }
      }
    }
  }
</script>
