<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <div class="row">
      <div class="col-sm-3">
        <h4 qid="third-parties-page-title" :class="{'mb-1': getGuide('third_party_list_page')}">
          {{ $t('maintenance.third_parties.title') }}
        </h4>
        <div>
          <span v-if="getGuide('third_party_list_page')">
            <GuideButton :guide="getGuide('third_party_list_page')"></GuideButton>
          </span>
        </div>
      </div>
      <div class="col-sm-9 text-right">
        <button class="btn btn-secondary-dark float-right"
                @click="handleFilterButton(false)"
        >
          <font-awesome-icon icon="filter"/>
        </button>
        <button class="btn btn-secondary-dark float-right"
                @click="handleDownloadModal"
                qid="download-table-button"
                v-if="isGranted(permissions.MAINTENANCE_VIEW)"
        >
          <font-awesome-icon icon="file-download"/>
        </button>
        <router-link
            to="/contracts/create"
            class="btn btn-success float-right"
            qid="create-tp-button"
            v-if="isGranted(permissions.THIRD_PARTY_CREATE)"
        >
          <font-awesome-icon icon="plus-circle"/> {{ $t('maintenance.third_parties.create_button') }}
        </router-link>
        <router-link
            to="/third-parties"
            class="btn btn-secondary float-right"
            qid="create-tp-button"
            v-if="isGranted(permissions.MAINTENANCE_VIEW)"
        >
          {{ $t('maintenance.third_parties.manage_vendors') }}
        </router-link>
        <b-form-input
          type="text"
          v-model="filters.keywordValue"
          class="float-right mw-300 mr-3 search-bar"
          qid="search-tp-input"
          v-on:input="debounceInput"
          :placeholder="$t('maintenance.third_parties.search_placeholder')"
        ></b-form-input>
      </div>
    </div>

    <div class="row" :class="{'table-with-filters': filterOpened}">
      <div class="col-sm-10 table-container">
        <div class="">
          <table class="table table-responsive-md table-list">
            <thead>
              <tr>
                <th scope="col"
                    class="cursor-pointer"
                    @click="selectSort('name')"
                    qid="sort-tp-name"
                >
                  {{ $t('maintenance.third_parties.table.name') }}
                  <span class="ml-2">
                    <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'name'"/>
                    <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'name'"/>
                    <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'name'"/>
                  </span>
                </th>
                <th scope="col" v-if="tableColumns.responsibles">
                  {{ $t('processes.table.owners') }}
                </th>
                <th scope="col" v-if="tableColumns.tags">
                  {{ $t('processes.table.tags') }}
                </th>
                <th scope="col" v-if="tableColumns.vendors">
                  {{ $t('maintenance.third_parties.table.vendors') }}
                </th>
                <th scope="col"
                    class="cursor-pointer"
                    @click="selectSort('type')"
                    qid="sort-tp-type"
                    v-if="tableColumns.type"
                >
                  {{ $t('table.category') }}
                  <span class="ml-2">
                    <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'type'"/>
                    <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'type'"/>
                    <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'type'"/>
                  </span>
                </th>
                <th scope="col"
                    class="cursor-pointer"
                    @click="selectSort('updated_at')"
                    qid="sort-p-name"
                >
                  {{ $t('system.last_updated') }}
                  <span class="ml-2">
                    <font-awesome-icon class="text-muted" icon="sort" v-if="selectedSort !== 'updated_at'"/>
                    <font-awesome-icon icon="caret-down" v-if="selectedDirection === 'desc' && selectedSort === 'updated_at'"/>
                    <font-awesome-icon icon="caret-up" v-if="selectedDirection === 'asc' && selectedSort === 'updated_at'"/>
                  </span>
                </th>
                <th width="180" class="text-right">
                  <b-nav-item-dropdown right extra-toggle-classes="no-dropdown-caret" class="guide-settings">
                    <!-- Using 'button-content' slot -->
                    <template slot="button-content">
                      <span class="float-right" style="list-style: none;">
                        <font-awesome-icon size="lg" icon="cog" class="text-muted"/>
                      </span>
                    </template>

                    <div class="pl-4 pr-4">
                      <b-form-checkbox
                        v-model="tableColumns.responsibles"
                        name="checkbox-disable"
                        :value="1"
                        :unchecked-value="0"
                      >
                        {{ $t('processes.table.owners') }}
                      </b-form-checkbox>
                      <br>
                      <b-form-checkbox
                        v-model="tableColumns.tags"
                        name="checkbox-disable"
                        :value="1"
                        :unchecked-value="0"
                      >
                        {{ $t('processes.table.tags') }}
                      </b-form-checkbox>
                      <br>
                      <b-form-checkbox
                        v-model="tableColumns.vendors"
                        name="checkbox-disable"
                        :value="1"
                        :unchecked-value="0"
                      >
                        {{ $t('maintenance.third_parties.table.vendors') }}
                      </b-form-checkbox>
                      <br>
                      <b-form-checkbox
                        v-model="tableColumns.type"
                        name="checkbox-disable"
                        :value="1"
                        :unchecked-value="0"
                      >
                        {{ $t('table.category') }}
                      </b-form-checkbox>
                      <br>
                    </div>
                    <hr>
                    <div class="pl-4 pr-4">
                      <small class="text-muted">
                        * {{ $t('system.global_table_expand_explainer_text') }}
                      </small>
                    </div>
                  </b-nav-item-dropdown>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="object in tableItems">
                <td>
                  <router-link
                      :to="{ name: 'contracts.view', params: { slug: object.slug }}"
                      qid="tp-view-page-link"
                  >
                    {{object.name}}
                  </router-link>
                </td>
                <td v-if="tableColumns.responsibles">
                  <span class="mr-1 text-normal" v-for="user in getReducedUsers(object.users)">
                    <span qid="profile-photo">
                      <Avatar :object="user" size="xs"></Avatar>
                    </span>
                  </span>
                  <span v-if="object.users.length > 3"
                        class="badge cursor-pointer"
                        @click="openUsersModal(object)"
                  >
                    <font-awesome-icon icon="users"/>
                    + {{ object.users.length - 3 }}
                  </span>
                </td>
                <td v-if="tableColumns.tags">
                  <span v-for="tag in getReducedTags(object.tags_translated)" qid="p-tags-list">
                    <span class="badge" :title="tag.name_translated">
                      <font-awesome-icon icon="tag"/>
                      {{ tag.name_translated | truncate(35) }}
                    </span>
                  </span>
                  <span v-if="object.tags_translated.length > 3"
                        class="badge cursor-pointer"
                        @click="openTagsModal(object)"
                  >
                    <font-awesome-icon icon="tags"/>
                    + {{ object.tags_translated.length - 3 }}
                  </span>
                </td>
                <td v-if="tableColumns.vendors">
                  <span class="mr-1 text-normal" v-for="item in getReducedVendors(object.vendors)">
                    <span qid="profile-photo">
                      <Avatar :object="item" size="xs"></Avatar>
                      {{ item.name }}
                    </span>
                  </span>
                  <span v-if="object.vendors.length > 3"
                        class="badge cursor-pointer"
                        @click="openVendorsModal(object)"
                  >
                    + {{ object.vendors.length - 3 }}
                  </span>
                </td>
                <td v-if="tableColumns.type">
                  <span class="badge" v-if="object.type" :title="object.type">
                    <font-awesome-icon icon="shapes"/>
                    {{ object.type | truncate(35) }}
                  </span>
                </td>
                <td>
                  <span class="text-normal">
                    {{ object.updated_at | formatDate }}
                  </span>
                </td>
                <td align="right">
                  <span class="btn btn-edit"
                        qid="edit-tp-modal"
                        @click="showEditTPModal(object)"
                        v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
                  >
                    <font-awesome-icon icon="pen"/>
                  </span>
                  <!-- <ThirdPartyEditForm
                      :object="object"
                      @reloadContent="loadContent(currentPage)"
                      v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
                  ></ThirdPartyEditForm> -->
                  <span v-if="isGranted([permissions.THIRD_PARTY_DELETE, permissions.THIRD_PARTY_DELETE_OWN], object) && (!getClient.parent || (getClient.parent && object.partition))"
                        class="btn btn-delete"
                        qid="delete-tp-modal"
                        @click="showConfirmDeleteModal(object)"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </span>
                </td>
              </tr>
              <tr v-if="!loading && !tableItems.length" qid="no-results-row" class="text-center">
                <td colspan="10">
                  <h5 class="mt-3">
                    {{ $t('system.no_results') }}
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-3 pagination row">
          <div class="col-md-6">
            <div class="row">
              <div class="float-left">
                <span class="text-muted pagination-counter">
                  {{ $t('pagination.entries_per_page') }}:
                </span>
              </div>
              <div class="float-left">
                <multiselect
                    v-model="perPage"
                    :options="perPageOptions"
                    track-by="value"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    label="value"
                    qid="create-org-form-organization-level"
                    @select="perPageChanged"
                    :placeholder="selectedPerPage"
                    @remove="perPageRemoved"
                ></multiselect>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <b-pagination
                class="float-right"
                v-model="currentPage"
                :total-rows="rows"
                :per-page="selectedPerPage"
                aria-controls="my-table"
                @change="pageChanged"
                limit="1"
            >
              <span class="text-muted" slot="first-text"><font-awesome-icon icon="angle-double-left"/></span>
              <span class="text-muted" slot="prev-text"><font-awesome-icon icon="angle-left"/></span>
              <span class="text-muted" slot="next-text"><font-awesome-icon icon="angle-right"/></span>
              <span class="text-muted" slot="last-text"><font-awesome-icon icon="angle-double-right"/></span>
              <div slot="ellipsis-text">
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
                <b-spinner small type="grow"></b-spinner>
              </div>
              <span class="text-muted" slot="page" slot-scope="{ page, active }">
                <span v-if="active">{{getPerPage}}</span>
              </span>
            </b-pagination>
          </div>
        </div>
      </div>
      <div class="col-sm-2 filter-container">
        <div class="card">
          <div class="card-body">
            <b-form-group>
              <label qid="create-s-users-label">
                {{ $t('activities.create.owners_label') }}
              </label>
              <multiselect
                  v-model="filters.owners"
                  @select="debounceInput"
                  @remove="debounceInput"
                  :options="users"
                  :multiple="true"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  :taggable="false"
                  track-by="slug"
                  :placeholder="$t('activities.create.owners_placeholder')"
                  label="name"
                  @search-change="loadUsers"
                  qid="filter-s-form-owners"
              >
                <template slot="tag" slot-scope="props">
                  <span class="mr-3 user-tag">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-2">{{ props.option.name }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <Avatar :object="props.option" size="xs"></Avatar>
                  <span class="ml-2">
                    {{ props.option.name }}
                  </span>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="create-s-tags-label">
                {{ $t('activities.create.tags_label') }}
              </label>
              <multiselect
                  v-model="filters.tags"
                  @select="debounceInput"
                  @remove="debounceInput"
                  :options="tags"
                  :multiple="true"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  :taggable="true"
                  track-by="name_translated"
                  :placeholder="$t('activities.create.tags_placeholder')"
                  label="name_translated"
                  qid="filter-s-form-tags"
                  @search-change="loadTags"
                  @tag="addTag"
              ></multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="filter-tp-type-label">
                {{ $t('maintenance.third_parties.create.type_label') }}
              </label>
              <multiselect
                  v-model="filters.type"
                  :options="types"
                  :placeholder="$t('maintenance.third_parties.create.type_placeholder')"
                  @select="debounceInput"
                  @remove="debounceInput"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  track-by="type"
                  label="type"
                  qid="filter-tp-form-type"
              ></multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="filter-tp-type-label">
                {{ $t('maintenance.third_parties.create.vendor_label') }}
              </label>
              <multiselect
                  v-model="filters.vendor"
                  :options="vendorItems"
                  :placeholder="$t('maintenance.third_parties.create.vendor_placeholder')"
                  @select="debounceInput"
                  @remove="debounceInput"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  track-by="id"
                  label="name"
                  qid="filter-tp-form-type"
              ></multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="filter-tp-type-label">
                {{ $t('maintenance.third_parties.create.dpa_contract_type_label') }}
              </label>
              <multiselect
                  v-model="filters.third_country_contract_type"
                  :options="thirdCountryContractTypes"
                  :placeholder="$t('maintenance.third_parties.create.dpa_contract_type_placeholder')"
                  @select="debounceInput"
                  @remove="debounceInput"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  label="name"
                  qid="filter-tp-form-type"
              ></multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="filter-p-type-label">
                {{ $t('activities.processing_role.title') }}
              </label>
              <multiselect
                  v-model="filters.processingRole"
                  :options="processingRoles"
                  :placeholder="$t('activities.processing_role.pr_placeholder')"
                  @select="debounceInput"
                  @remove="debounceInput"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  track-by="name"
                  label="name"
                  qid="filter-p-form-process_group"
              ></multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="filter-p-type-label">
                {{ $t('validations.checklist.data_processor_agreement') }}
              </label>
              <multiselect
                  v-model="filters.dataProcessorAgreement"
                  :options="dpaItems"
                  :placeholder="$t('validations.checklist.data_processor_agreement')"
                  @select="debounceInput"
                  @remove="debounceInput"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  track-by="name"
                  label="name"
                  qid="filter-p-form-process_group"
              ></multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="filter-tp-type-label">
                {{ $t('maintenance.third_parties.create.document_category_label') }}
              </label>
              <multiselect
                  v-model="filters.document_category"
                  :options="categoryItems"
                  :placeholder="$t('maintenance.third_parties.create.document_category_label')"
                  @select="debounceInput"
                  @remove="debounceInput"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  track-by="category"
                  label="category"
                  qid="filter-tp-form-type"
              ></multiselect>
            </b-form-group>
            <b-form-group>
              <label qid="filter-tp-type-label">
                {{ $t('maintenance.third_parties.create.measure_label') }}
              </label>
              <multiselect
                  v-model="filters.measure"
                  :options="measures"
                  :placeholder="$t('maintenance.third_parties.create.measure_placeholder')"
                  @select="measureFilterChanged"
                  @remove="measureFilterChanged"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  track-by="id"
                  label="name"
                  qid="filter-tp-form-type"
              ></multiselect>
            </b-form-group>
            <b-form-group v-if="filters.measure && filters.measure.value == 'number'">
              <label qid="filter-tp-type-label">
                {{ $t('maintenance.third_parties.create.number_type_label') }}
              </label>
              <multiselect
                  v-model="filters.number_type"
                  :options="numberTypes"
                  :placeholder="$t('maintenance.third_parties.create.number_type_placeholder')"
                  @select="debounceInput"
                  @remove="debounceInput"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  track-by="type"
                  label="type"
                  qid="filter-tp-form-type"
              ></multiselect>
            </b-form-group>
            <b-form-group v-if="filters.measure && filters.measure.value == 'number'">
              <label qid="filter-tp-type-label">
                {{ $t('maintenance.third_parties.create.number_value_label') }}
              </label>
              <b-form-input
                type="text"
                v-model="filters.number_value"
                qid="search-tp-input"
                v-on:input="debounceInput"
                :placeholder="$t('maintenance.third_parties.create.number_value_placeholder')"
              ></b-form-input>
            </b-form-group>

            <b-form-group v-if="filters.measure && filters.measure.value == 'dropdown' && filters.measure.type == 'Yes/No'">
              <label qid="filter-tp-type-label">
                {{ $t('maintenance.questions.create.options_label') }}
              </label>
              <multiselect
                  v-model="filters.dropdown_value"
                  :options="yesNoTypes"
                  :placeholder="$t('maintenance.questions.create.options_label')"
                  @select="debounceInput"
                  @remove="debounceInput"
                  select-label="✔"
                  label="name"
                  track-by="value"
                  selected-label="✔"
                  deselect-label="x"
                  qid="filter-tp-form-type"
              ></multiselect>
            </b-form-group>
            <b-form-group v-if="filters.measure && filters.measure.value == 'dropdown' && filters.measure.type == 'Select'">
              <label qid="filter-tp-type-label">
                {{ $t('maintenance.questions.create.options_label') }}
              </label>
              <multiselect
                  v-model="filters.dropdown_value"
                  :options="getMeasureTypes(filters.measure)"
                  :placeholder="$t('maintenance.questions.create.options_label')"
                  @select="debounceInput"
                  @remove="debounceInput"
                  select-label="✔"
                  selected-label="✔"
                  deselect-label="x"
                  qid="filter-tp-form-type"
              ></multiselect>
            </b-form-group>
            <b-form-group v-if="filters.measure && filters.measure.value == 'date'">
              <label qid="filter-tp-type-label">
                {{ $t('system.date_from') }}
              </label>
              <datepicker
                  class="date-picker"
                  qid="filter-p-form-modified-to"
                  calendar-class="right"
                  @selected="debounceInput"
                  @remove="debounceInput"
                  v-model="filters.date_from"
                  :clear-button="true"
                  :placeholder="$t('system.date_from')"
              ></datepicker>
            </b-form-group>
            <b-form-group v-if="filters.measure && filters.measure.value == 'date'">
              <label qid="filter-tp-type-label">
                {{ $t('system.date_to') }}
              </label>
              <datepicker
                  class="date-picker"
                  qid="filter-p-form-modified-to"
                  calendar-class="right"
                  @selected="debounceInput"
                  @remove="debounceInput"
                  v-model="filters.date_to"
                  :clear-button="true"
                  :placeholder="$t('system.date_to')"
              ></datepicker>
            </b-form-group>
            <a href="#" @click="resetFilters" class="text-right" qid="filter-tp-reset">
              {{ $t('system.reset_filters') }}
            </a>
          </div>
        </div>
      </div>
    </div>


    <!-- Edit third party modal template -->
    <b-modal ref="edit-third-party" hide-footer @hide="hideEditTPModal">
      <div class="d-block text-center">
        <h4 qid="update-tp-title">
          {{ $t('maintenance.third_parties.update.title') }}
        </h4>
      </div>
      <b-alert v-if="error.length"
               show
               variant="danger"
               qid="edit-tp-error"
      >
        {{ error }}
      </b-alert>
      <b-form @submit="editTPFormSubmit" qid="edit-tp-form">
        <div class="form-container">
          <h5 class="mb-3">
            <label>{{ $t('maintenance.third_parties.create.name_section_label') }}</label>
          </h5>

          <b-form-group>
            <label qid="create-tp-name-label">
              {{ $t('maintenance.third_parties.create.name_label') }}
            </label>
            <span class="text-red">*</span>
            <b-form-input
              qid="create-tp-form-name"
              type="text"
              v-model="tpForm.name"
              required
              :maxLength="255"
              :placeholder="$t('maintenance.third_parties.create.name_placeholder')"
            ></b-form-input>
          </b-form-group>
          <b-form-group>
            <label qid="create-tp-contact-label">
              {{ $t('maintenance.third_parties.create.contract_reference_label') }}
            </label>
            <b-form-input
              qid="create-tp-form-contact"
              type="text"
              v-model="tpForm.reference"
              :maxLength="255"
              :placeholder="$t('maintenance.third_parties.create.contract_reference_placeholder')"
            ></b-form-input>
          </b-form-group>

          <h5 class="mb-2 mt-4">
            <label>{{ $t('maintenance.third_parties.create.ownership_section_label') }}</label>
          </h5>

          <b-form-group>
            <label qid="create-p-users-label">
              {{ $t('processes.create.owners_label') }}
            </label>
            <multiselect
                v-model="selectedUsers"
                :options="users"
                :multiple="true"
                :taggable="false"
                track-by="slug"
                :placeholder="$t('processes.create.owners_placeholder')"
                label="name"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @remove="removeUserFromDropdown"
                @search-change="loadUsers"
                qid="create-p-users-select"
            >
              <template slot="tag" slot-scope="props">
                <span></span>
              </template>
              <template slot="option" slot-scope="props">
                <Avatar :object="props.option" size="xs"></Avatar>
                <span class="ml-2">
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
            <div class="form-control auto-height mt-1" v-if="selectedUsers && selectedUsers.length">
              <div v-for="(user, index) in selectedUsers" class="mt-1 mb-1">
                <div class="row">
                  <div class="col-sm-1">
                    <font-awesome-icon
                      icon="star"
                      :class="{'color-yellow': user.owner, 'text-light': !user.owner}"
                      class="cursor-pointer"
                      @click="setOwner(index)"
                    />
                  </div>
                  <div class="col-sm-10">
                    <Avatar :object="user" size="xs" :noOwner="true"></Avatar>
                    <span class="ml-2">{{ user.name }}</span>
                  </div>
                  <div class="col-sm-1">
                    <font-awesome-icon
                      icon="times"
                      class="cursor-pointer"
                      @click="removeUser(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span class="text-light">
              * {{ $t('maintenance.third_parties.create.owners_tip') }}
            </span>
          </b-form-group>

          <h5 class="mb-3">
            <label>{{ $t('maintenance.third_parties.create.groups_section_label') }}</label>
          </h5>

          <b-form-group>
            <label qid="create-ac-pg-label">
              {{ $t('maintenance.third_parties.create.vendor_label') }}
            </label>
            <span class="text-red">*</span>
            <div class="row">
              <div class="col-sm-7">
                <multiselect
                    v-model="selectedVendors"
                    :options="vendorItems"
                    :multiple="true"
                    :taggable="false"
                    track-by="slug"
                    :placeholder="$t('maintenance.third_parties.create.vendor_placeholder')"
                    label="name"
                    :select-label="$t('system.dropdown_select_label')"
                    :selected-label="$t('system.dropdown_selected_label')"
                    :deselect-label="$t('system.dropdown_deselect_label')"
                    @search-change="loadVendors"
                    qid="create-p-users-select"
                >
                  <template slot="tag" slot-scope="props">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-1">
                      {{ props.option.name }}
                    </span>
                    <font-awesome-icon
                      icon="times"
                      class="cursor-pointer mr-2"
                      @click="removeVendor(props.option)"
                    />
                  </template>
                  <template slot="option" slot-scope="props">
                    <Avatar :object="props.option" size="xs"></Avatar>
                    <span class="ml-1">
                      {{ props.option.name }}
                    </span>
                  </template>
                </multiselect>
              </div>
              <div class="col-sm-5">
                <VendorCreateForm @reloadContent="updateVendors" v-if="isGranted(permissions.THIRD_PARTY_CREATE)"></VendorCreateForm>
              </div>
            </div>
          </b-form-group>

          <b-form-group :class="{'multiselect--show-input': typeInput.length}">
            <label qid="edit-tp-type-label">
              {{ $t('maintenance.third_parties.create.type_label') }}
            </label>
            <multiselect
                v-model="selectedType"
                :options="types"
                :placeholder="$t('maintenance.third_parties.create.type_placeholder')"
                label="type"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                track-by="type"
                :preserveSearch="true"
                @search-change="loadTypes"
                qid="edit-tp-form-type"
            >
              <template slot="noResult" slot-scope="props">
                {{ $t('maintenance.third_parties.create.type_no_results') }}
              </template>
            </multiselect>
          </b-form-group>

          <b-form-group>
            <label qid="create-do-tags-label">
              {{ $t('processes.create.tags_label') }}
            </label>
            <multiselect
                v-model="selectedTags"
                :options="tags"
                :multiple="true"
                :taggable="true"
                :select-label="$t('system.dropdown_select_label')"
                :selected-label="$t('system.dropdown_selected_label')"
                :deselect-label="$t('system.dropdown_deselect_label')"
                @search-change="loadTags"
                track-by="name_translated"
                :placeholder="$t('processes.create.tags_placeholder')"
                label="name_translated"
                qid="create-do-tags-select"
                @tag="addTag"
            ></multiselect>
          </b-form-group>

          <h5 class="mb-3 mt-4">
            <label>{{ $t('maintenance.third_parties.create.features_section_label') }}</label>
          </h5>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.contract_measures_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.contract_measures"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.data_privacy_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.data_privacy_feature"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.risk_managements_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.risk_management_feature"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.projects_and_assessments_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.project_and_assessment"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.relations_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.relations"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-sm-6">
              <label>
                {{ $t('maintenance.third_parties.create.validation_feature_label') }}
              </label>
            </div>
            <div class="col-sm-6 text-right">
              <b-form-radio-group
                v-model="tpForm.validation_activation"
                :options="checklistOptions"
                qid="create-tp-contract-measures"
              ></b-form-radio-group>
            </div>
          </div>

        </div>
        <div class="text-right">
          <b-button class="mt-3"
                    variant="secondary"
                    @click="hideEditTPModal"
                    qid="edit-tp-cancel-button"
          >
            {{ $t('maintenance.third_parties.create.cancel') }}
          </b-button>
          <b-button type="submit"
                    class="mt-3 ml-3"
                    variant="success"
                    :disabled="buttonDisabled"
                    qid="edit-tp-submit-button"
                    v-if="isGranted([permissions.THIRD_PARTY_EDIT, permissions.THIRD_PARTY_EDIT_OWN], selectedTP) && (!getClient.parent || (getClient.parent && selectedTP.partition))"
          >
            <span v-if="buttonDisabled" class="mr-1">
              <font-awesome-icon icon="spinner" size="sm" class="fa-spin"/>
            </span>
            {{ $t('maintenance.third_parties.create.submit') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>


    <!-- Delete third party confirm modal template -->
    <b-modal ref="delete-third-party" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-tp-title">
          {{ $t('maintenance.third_parties.delete.title') }}
        </h4>
      </div>
      <h5 class="text-center">
        {{ $t('maintenance.third_parties.delete.description') }} {{ itemToDelete.name }}
      </h5>
      <h5 class="text-center">
        {{ $t('maintenance.third_parties.delete.are_you_sure') }}
      </h5>
      <div class="text-center">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="hideConfirmDeleteModal"
                  qid="delete-tp-cancel-button"
        >
          {{ $t('maintenance.third_parties.delete.cancel') }}
        </b-button>
        <b-button @click="confirmDeleteTP(itemToDelete)"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-tp-submit-button"
                  v-if="isGranted([permissions.THIRD_PARTY_DELETE, permissions.THIRD_PARTY_DELETE_OWN], itemToDelete) && (!getClient.parent || (getClient.parent && itemToDelete.partition))"
        >
          {{ $t('maintenance.third_parties.delete.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Show data objects modal -->
    <b-modal ref="view-data-objects" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-tp-title">
          {{ $t('maintenance.third_parties.data_objects.title') }}
        </h4>
      </div>
      <div class="mt-5">
        <div class="" v-for="dataObject in selectedTP.data_objects">
          <div class="mb-4">
            <span class="badge badge-info float-right">
              {{ $t('classification.' + $options.filters.slugify(dataObject.classification)) }}
            </span>
            <span>{{ dataObject.name }}</span>
          </div>
        </div>
      </div>
      <div class="float-right">
        <b-button class="mt-3"
                  variant="success"
                  @click="hideDataObjectsModal"
                  qid="delete-tp-cancel-button"
        >
          {{ $t('maintenance.third_parties.data_objects.close') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Download modal template -->
    <b-modal ref="download-modal" hide-footer>
      <div class="d-block text-center">
        <h4 qid="delete-s-title">
          {{ $t('download_modal.title') }}
        </h4>
      </div>
      <div class="mt-2 mb-2">

        <label class="mt-2">
          {{ $t('download_modal.label.select') }}
        </label>

        <div class="row mt-2 mb-2">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.label.select_all') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
              v-model="selectAll"
              :options="radioOptions"
              qid="basic-info"
            ></b-form-radio-group>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.value.basic_info') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
              v-model="downloadForm.basic_info"
              :options="radioOptions"
              qid="basic-info"
            ></b-form-radio-group>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.value.owners') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
              v-model="downloadForm.owners"
              :options="radioOptions"
              qid="owners"
            ></b-form-radio-group>
          </div>
        </div>

        <label class="mt-2">
          {{ $t('download_modal.value.data') }}
        </label>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.value.data') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
              v-model="downloadForm.data"
              :options="radioOptions"
              qid="systems"
            ></b-form-radio-group>
          </div>
        </div>

        <label class="mt-2">
          {{ $t('download_modal.value.third_parties') }}
        </label>

        <div class="row">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.value.third_parties') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
                v-model="downloadForm.third_parties"
                :options="radioOptions"
                qid="third_parties"
            ></b-form-radio-group>
          </div>
        </div>

        <label class="mt-2" v-if="getClient.contractAdvancedModule">
          {{ $t('download_modal.value.documents') }}
        </label>

        <div class="row" v-if="getClient.contractAdvancedModule">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.value.documents') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
                v-model="downloadForm.documents"
                :options="radioOptions"
                qid="documents"
            ></b-form-radio-group>
          </div>
        </div>

        <label class="mt-2" v-if="getClient.contractAdvancedModule">
          {{ $t('download_modal.value.measures') }}
        </label>

        <div class="row" v-if="getClient.contractAdvancedModule">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.value.measures') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
                v-model="downloadForm.measures"
                :options="radioOptions"
                qid="measures"
            ></b-form-radio-group>
          </div>
        </div>

        <label class="mt-2" v-if="getClient.contractAdvancedModule">
          {{ $t('download_modal.value.contacts') }}
        </label>

        <div class="row" v-if="getClient.contractAdvancedModule">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.value.contacts') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
                v-model="downloadForm.contacts"
                :options="radioOptions"
                qid="measures"
            ></b-form-radio-group>
          </div>
        </div>

        <label class="mt-2" v-if="getClient.contractAdvancedModule && getClient.gdprModule">
          {{ $t('maintenance.third_parties.tab.data_privacy') }}
        </label>

        <div class="row" v-if="getClient.contractAdvancedModule && getClient.gdprModule">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.value.subdata_processors') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
                v-model="downloadForm.subdata_processors"
                :options="radioOptions"
                qid="measures"
            ></b-form-radio-group>
          </div>
        </div>


        <label class="mt-2" v-if="getClient.riskModule">
          {{ $t('download_modal.value.risk_control_module') }}
        </label>

        <div class="row" v-if="getClient.riskModule">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.value.risk_scenarios') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
              v-model="downloadForm.risk_scenarios"
              :options="radioOptions"
              qid="risk_scenarios"
            ></b-form-radio-group>
          </div>
        </div>
        <div class="row" v-if="getClient.riskModule">
          <div class="col-sm-6">
            <p class="text-normal">
              {{ $t('download_modal.value.risk_validation_log') }}
            </p>
          </div>
          <div class="col-sm-6 text-right">
            <b-form-radio-group
              v-model="downloadForm.risk_validation_log"
              :options="radioOptions"
              qid="risk_validation_log"
            ></b-form-radio-group>
          </div>
        </div>

      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="closeDownloadModal"
                  qid="delete-s-cancel-button"
        >
          {{ $t('activities.delete.cancel') }}
        </b-button>
        <b-button @click="downloadTable()"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="delete-s-submit-button"
        >
          {{ $t('system.download') }}
        </b-button>
      </div>
    </b-modal>


    <ShowMoreModal
        :items="selectedItem.tags_translated"
        :show="showTagsModal"
        @closeViewModal="closeTagsModal"
        type="tags"
    ></ShowMoreModal>

    <ShowMoreModal
        :items="selectedItem.users"
        :show="showUsersModal"
        @closeViewModal="closeUsersModal"
        type="users"
    ></ShowMoreModal>

    <ShowMoreModal
        :items="selectedItem.vendors"
        :show="showVendorsModal"
        @closeViewModal="closeVendorsModal"
        type="vendors"
    ></ShowMoreModal>

  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import Datepicker from 'vuejs-datepicker';
  import Avatar from '@/components/Avatar.vue';
  import ShowMoreModal from '@/components/ShowMoreModal.vue';
  import moment from 'moment';
  import roleCheck from '@/util/ACL/roleCheck.js'
  import ThirdPartyEditForm from '@/views/ThirdParty/ThirdPartyEditForm.vue';
  import VendorCreateForm from '@/views/ThirdParty/VendorCreateForm.vue';
  import GuideButton from '@/components/guide/GuideButton.vue';


  export default {
    name: 'ThirdParties',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('maintenance.third_parties.title'),
            active: true
          }
        ],
        tableItems: [],

        // Pagination
        perPageOptions: [
          { name: '10', value: '10' },
          { name: '20', value: '20' },
          { name: '50', value: '50' },
          { name: '100', value: '100' }
        ],
        perPage: '',
        selectedPerPage: "10",
        currentPage: 1,
        totalRows: 0,

        itemToDelete: {},
        tpForm: {
          name: '',
          description: '',
          reference: '',
          type: [],
          client:'',
          contract_measures: 1,
          data_privacy_feature: 1,
          risk_management_feature: 1,
          project_and_assessment: 1,
          relations: 1,
          validation_activation: 1,
          links: [],
          vendors: [],
          tags: []
        },
        error: '',
        countries: [],
        selectedCountries: [],
        selectedType: [],
        selectedUsers: [],
        inspectionItems: [
          { name: this.$t('maintenance.third_parties.inspection.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.inspection.external_audit'), value: 'External audit' },
          { name: this.$t('maintenance.third_parties.inspection.site_visit'), value: 'Site visit' },
          { name: this.$t('maintenance.third_parties.inspection.third-party_self-audit'), value: 'Third-party self-audit' }
        ],
        dpaItems: [
          { name: this.$t('maintenance.third_parties.data_processor_agreement.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.data_processor_agreement.yes'), value: 'Yes' },
          { name: this.$t('maintenance.third_parties.data_processor_agreement.n/a'), value: 'N/A' }
        ],
        dpaContractTypes: [
          { name: this.$t('maintenance.third_parties.dpa_contract_type.contract_model_clause'), value: 'Contract model clause' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.adequacy_decision'), value: 'Adequacy decision' },
          { name: this.$t('maintenance.third_parties.dpa_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
        ],
        transfersDisclosuresThirdCountries : [
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.no'), value: 'No' },
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.yes'), value: 'Yes' },
          { name: this.$t('maintenance.third_parties.transfers_disclosures_third_countries.n/a'), value: 'N/A' }
        ],
        thirdCountryContractTypes: [
          { name: this.$t('maintenance.third_parties.third_country_contract_type.contract_model_clause'), value: 'Contract model clause' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.eu/us_privacy_shield'), value: 'EU/US Privacy Shield' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.adequacy_decision'), value: 'Adequacy decision' },
          { name: this.$t('maintenance.third_parties.third_country_contract_type.binding_corporate_rules'), value: 'Binding corporate rules' }
        ],
        processingRoles: [
          { name: this.$t('maintenance.third_parties.processing_role.data_controller'), value: 'Data controller'},
          { name: this.$t('maintenance.third_parties.processing_role.data_processor'), value: 'Data processor'},
          { name: this.$t('activities.processing_role.joint_controller_label'), value: 'Joint controller' }
        ],
        yesNoTypes: [
          { name: this.$t('system.yes'), value: 'yes' },
          { name: this.$t('system.no'), value: 'no' }
        ],
        selectedSort: '',
        selectedDirection: '',
        filterOpened: false,
        keywordValue: '',
        categoryItems: [],
        filters: {
          type: '',
          owners: [],
          tags: [],
          data_processor_agreement: '',
          dpa_updated_at: '',
          inspection: '',
          inspection_updated_at: '',
          date_from: '',
          date_to: '',
          dropdown_value: '',
          processingRole: '',
          parent: 0,
          vendor: '',
          document_category: '',
          keywordValue: '',
          third_country_contract_type: []
        },
        tableColumns: {
          responsibles: 1,
          tags: 1,
          vendors: 1,
          type: 1
        },
        types: [],
        numberTypes: [
          { name: '<=', type: '<=' },
          { name: '=', type: '=' },
          { name: '>=', type: '>=' },
        ],
        vendors: [],
        measures: [],
        gdprContractTypes: [],
        typeInput: '',
        dataObjects: [],
        selectedDataObjects: [],
        loading: true,
        selectedTP: '',
        buttonDisabled: false,
        myGuides: [],
        selectedTags: [],
        tags: [],
        users: [],
        newTags: [],
        selectedItem: {},
        showTagsModal: false,
        showVendorsModal: false,
        showUsersModal: false,
        radioOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0}
        ],
        selectAll: '',
        downloadForm: {
          basic_info: 1,
          owners: 0,
          data: 0,
          risk_scenarios: 0,
          risk_validation_log: 0,
          third_parties: 0,
          documents: 0,
          measures: 0,
          contacts: 0,
          subdata_processors: 0
        },
        checklistOptions: [
          {text: this.$t('system.yes'), value: 1},
          {text: this.$t('system.no'), value: 0},
        ],
        selectedVendors: [],
        selectedTP: {},
        vendorItems: [],
        vendorToView: {},
        viewVendorModal: false
      }
    },
    components: {
      Datepicker,
      Avatar,
      ShowMoreModal,
      ThirdPartyEditForm,
      VendorCreateForm,
      GuideButton
    },
    computed: {
      rows() {
        // Use computed value for rows because of dynamic update
        return this.totalRows
      },
      getClient() {
        return this.$store.state.client
      },
      getFilters() {
        return this.$store.state.activeFilters
      },
      getTableColumns() {
        if (localStorage.getItem('tableColumns')) {
          return JSON.parse(localStorage.getItem('tableColumns'))
        } else {
          return {}
        }
      },
      getStorePerPage() {
        return this.$store.state.perPage
      },
      getPerPage() {
        let page = this.currentPage;
        let selectedFrom = (page-1) * Number(this.selectedPerPage) + 1;
        let selectedTo = (page-1) * Number(this.selectedPerPage) + Number(this.selectedPerPage);
        let selectedOf = this.totalRows;

        if(selectedTo > selectedOf){
          selectedTo = selectedOf
        }

        return selectedFrom + " - " + selectedTo + " " + this.$t('system.of') + " " + selectedOf
      }
    },
    watch: {
      // Watch when client changes in store and trigger api reload
      getClient: function () {
        this.loadContent()
        this.loadTypes();
        this.loadTags();
        this.loadVendors()
        this.loadCategories()
        this.loadUsers()
        if (this.getTableColumns.third_parties) {
          this.tableColumns = this.getTableColumns.third_parties
        }
      },
      selectAll: function (value) {
        this.downloadForm.basic_info = value
        this.downloadForm.owners = value
        this.downloadForm.data = value
        this.downloadForm.third_parties = value

        if (this.getClient.riskModule) {
          this.downloadForm.risk_scenarios = value
          this.downloadForm.risk_validation_log = value
        }

        if (this.getClient.contractAdvancedModule) {
          this.downloadForm.documents = value
          this.downloadForm.measures = value
          this.downloadForm.contacts = value
        }

        if (this.getClient.contractAdvancedModule && this.getClient.gdprModule) {
          this.downloadForm.subdata_processors = value
        }

      }
    },
    methods: {
      debounceInput: _.debounce(function() {
        this.loadContent()
      }, 400),
      measureFilterChanged() {
        this.filters.number_type = []
        this.filters.number_value = ''
        this.filters.dropdown_value = ''
        this.filters.date_from = ''
        this.filters.date_to = ''
        this.debounceInput()
      },
      goToHome() {
        this.$router.push('/')
      },
      getMeasureTypes(object) {
        let array = JSON.parse(object.type_options)
        return array;
      },
      showEditTPModal(object) {
        this.selectedTP = object
        let self = this;

        self.selectedUsers = [];
        if (object.users && object.users.length) {
          _.forEach(object.users, function (user) {
            let matchedUser = _.cloneDeep(user)
            if (user.id == object.owner) {
              matchedUser.owner = true
            }
            self.selectedUsers.push(matchedUser)
          })
        }

        if (object.tags_translated && object.tags_translated.length) {
          this.selectedTags = _.cloneDeep(object.tags_translated);
        }

        if (object.vendors && object.vendors.length) {
          this.selectedVendors = _.cloneDeep(object.vendors);
        }


        if (object.type) {
          this.selectedType = []
          _.forEach(this.types, function(item) {
            if (item.type == object.type) {
              self.selectedType = item;
            }
          });
        }

        this.error = ''
        this.tpForm.slug = object.slug;
        this.tpForm.name = object.name;
        this.tpForm.description = object.description;
        this.tpForm.reference = object.reference;
        this.tpForm.contract_measures = object.contract_measures;
        this.tpForm.data_privacy_feature = object.data_privacy_feature;
        this.tpForm.risk_management_feature = object.risk_management_feature;
        this.tpForm.project_and_assessment = object.project_and_assessment;
        this.tpForm.relations = object.relations;
        this.tpForm.validation_activation = object.validation_activation;

        this.$refs['edit-third-party'].show()
      },
      hideEditTPModal() {
        this.typeInput = '';
        this.selectedUsers = []
        this.selectedTags = []
        this.selectedVendors = []
        this.selectedType = []
        this.selectedTP = []
        this.error = ''
        this.loadUsers()
        this.$refs['edit-third-party'].hide()
      },
      editTPFormSubmit(evt) {
        this.buttonDisabled = true;
        var moment = require('moment');
        // Keep the reference in a variable
        // to use it inside function called when promise returned
        let self = this;
        let selectedOwner = false;
        evt.preventDefault();

        this.tpForm.client = this.getClient.slug;

        this.tpForm.users = [];
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            this.tpForm.users.push(this.selectedUsers[i].id);
            if (this.selectedUsers[i].owner) {
              selectedOwner = true;
              this.tpForm.owner = this.selectedUsers[i].id;
            }
          }
        } else {
          selectedOwner = true;
        }

        this.tpForm.tags = [];
        if (this.selectedTags && this.selectedTags.length) {
          for (var i = 0; i < this.selectedTags.length; i++) {
            this.tpForm.tags.push(this.selectedTags[i].name_translated);
          }
        }

        this.tpForm.vendors = [];
        if (this.selectedVendors && this.selectedVendors.length) {
          for (var i = 0; i < this.selectedVendors.length; i++) {
            this.tpForm.vendors.push(this.selectedVendors[i].id);
          }
        }

        if (this.typeInput && this.typeInput.length) {
          this.tpForm.type = this.typeInput
        } else {
          if (this.selectedType && this.selectedType.type) {
            this.tpForm.type = this.selectedType.type
          } else {
            this.tpForm.type = '';
          }
        }

        if (selectedOwner && this.selectedVendors && this.selectedVendors.length) {
          piincHttp.put('third-parties/' + this.tpForm.slug, this.tpForm).then(function(response) {
            self.hideEditTPModal();
            self.buttonDisabled = false;
            self.$emit('reloadContent', response.data);
            self.loadTypes();
            self.loadContent(self.currentPage);
            self.$toastr('success', self.$t('maintenance.third_parties.update.success'))
          }, function(error) {
            if (error.status !== 200) {
              self.buttonDisabled = false;
              self.error = error.data.message;
            }
          })
        } else {
          this.error = ''
          if (!selectedOwner) {
            this.error += this.$t('system.owners_error_message')
          }
          if (!this.selectedVendors || (this.selectedVendors && !this.selectedVendors.length)) {
            this.error +=  ' ' + this.$t('maintenance.third_parties.create.selected_vendors_error_message')
          }
          this.buttonDisabled = false;
        }
      },
      updateVendors(object) {
        this.loadVendors()
      },
      loadVendors(searchQuery) {
        let self = this;
        piincHttp.get('vendors', { params:
          {
            client: this.getClient.slug,
            search: searchQuery !== '' ? searchQuery : undefined,
            parent: this.getClient.parent ? 1 : undefined,
            per_page: 100
          }
        }).then(function(response) {
          self.vendorItems = response.data.items
          if (self.vendorItems && !self.vendorItems.length) {
            self.vendorItems.push({category: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadCategories(searchQuery) {
        let self = this;
        piincHttp.get('third-parties/documents/categories', { params:
          {
            client: this.getClient.slug,
            search: searchQuery !== '' ? searchQuery : undefined,
            parent: this.getClient.parent ? 1 : undefined,
            per_page: 100
          }
        }).then(function(response) {
          self.categoryItems = response.data
        }, function() {});
      },
      removeUser(index) {
        let selectedItem = this.selectedUsers[index]
        selectedItem.owner = false;
        this.$set(this.selectedUsers, index, selectedItem)
        // Remove item from selected users array
        this.selectedUsers.splice(index, 1);
      },
      removeVendor(item) {
        let selectedItem = item
        let index = _.findIndex(this.selectedVendors, function(object) { return object.id == item.id; });
        this.$set(this.selectedVendors, index, selectedItem)
        // Remove item from selected users array
        this.selectedVendors.splice(index, 1);
      },
      removeUserFromDropdown(item) {
        item.owner = false;
      },
      resetFilters() {
        this.filters.type = ''
        this.filters.owners = []
        this.filters.tags = []
        this.filters.measure = []
        this.filters.number_type = []
        this.filters.number_value = ''
        this.filters.vendor = []
        this.filters.document_category = []
        this.filters.third_country_contract_type = []
        this.filters.third_country_transfer = ''
        this.filters.only_data_processors = ''
        this.filters.dropdown_value = ''
        this.filters.date_from = ''
        this.filters.date_to = ''
        this.filters.processingRole = '';
        this.filters.dataProcessorAgreement = '';
        this.filters.parent = 0

        this.debounceInput()
      },
      setOwner(index) {
        let selectedItem = this.selectedUsers[index]
        _.forEach(this.selectedUsers, function (item) {
          item.owner = false;
        })
        selectedItem.owner = true;
        this.$set(this.selectedUsers, index, selectedItem)
      },
      handleFilterButton(forceOpened) {
        if (!this.filterOpened) {
          this.loadTypes()
          this.loadMeasures()
          this.loadTags();
          this.loadVendors();
          this.loadCategories();
          this.loadUsers();
        }
        if (forceOpened) {
          this.filterOpened = true
        } else {
          this.filterOpened = !this.filterOpened
        }
      },
      getReducedTags(tags) {
        let reducedTags = []
        if (tags.length > 3) {
          reducedTags = _.cloneDeep(tags);
          reducedTags = reducedTags.slice(0, 3);
        } else {
          reducedTags = tags;
        }
        return reducedTags;
      },
      getReducedVendors(vendors) {
        let reducedVendors = []
        if (vendors) {
          if (vendors.length > 3) {
            reducedVendors = _.cloneDeep(vendors);
            reducedVendors = reducedVendors.slice(0, 3);
          } else {
            reducedVendors = vendors;
          }
        }
        return reducedVendors;
      },
      openTagsModal(object) {
        this.selectedItem = object
        this.showTagsModal = true
      },
      closeTagsModal() {
        this.selectedItem = {}
        this.showTagsModal = false
      },
      getReducedUsers(users) {
        let reducedUsers = []
        if (users) {
          if (users.length > 3) {
            reducedUsers = _.cloneDeep(users);
            reducedUsers = reducedUsers.slice(0, 3);
          } else {
            reducedUsers = users;
          }
        }
        return reducedUsers;
      },
      openUsersModal(object) {
        this.selectedItem = object
        this.showUsersModal = true
      },
      closeUsersModal() {
        this.selectedItem = {}
        this.showUsersModal = false
      },
      openVendorsModal(object) {
        this.selectedItem = object
        this.showVendorsModal = true
      },
      closeVendorsModal() {
        this.selectedItem = {}
        this.showVendorsModal = false
      },
      handleDownloadModal() {
        this.$refs['download-modal'].show()
      },
      closeDownloadModal() {
        this.$refs['download-modal'].hide()
      },
      downloadTable() {
        let self = this;
        this.downloadForm.client = this.getClient.slug;
        piincHttp.post('third-parties/download', this.downloadForm).then(function() {
          self.closeDownloadModal();
          self.$toastr('success', self.$t('exports.success_message'))
        }, function(error) {
          if (error.status !== 200) {
            self.error = error.data.message;
          }
        })
      },
      getGuide(code) {
        let existingGuide = _.find(this.myGuides, function(object) {
          return object.scope == code && object.type == 'Third party' && object.enabled
        });
        return existingGuide
      },
      loadGuides(code) {
        let self = this;
        let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

        piincHttp.get('guides', { params:
          {
            per_page: 1,
            language: 'en',
            type: 'Third party',
            scope: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myGuides.push(response.data.items[0])
          }
        }, function() {});
      },
      selectSort(sort) {
        // If old and new sort are same, switch just direction
        if (sort === this.selectedSort) {
          this.selectedDirection = this.selectedDirection === 'desc' ? 'asc' : 'desc';
        } else {
          // If different sort, set sort and set direction to default
          this.selectedDirection = 'desc'
          this.selectedSort = sort;
        }
        this.loadContent();
      },
      loadContent(page, perPage) {

        if (this.getStorePerPage) {
          this.selectedPerPage = this.getStorePerPage;
        }

        // If page is not defined, set page to default 1
        let pageNumber = page ? page : 1;
        let perPageNumber = perPage ? perPage : this.selectedPerPage;
        let self = this;
        let formOwners = [];
        let formTags = [];

        if (this.filters.owners && this.filters.owners.length) {
          for (var i = 0; i < this.filters.owners.length; i++) {
            formOwners[i] = this.filters.owners[i].id;
          }
          this.handleFilterButton(true)
        }

        if (this.filters.tags && this.filters.tags.length) {
          for (var j = 0; j < this.filters.tags.length; j++) {
            formTags[j] = this.filters.tags[j].id;
          }
          this.handleFilterButton(true)
        }

        if (
          (this.filters.modifiedBy && this.filters.modifiedBy.id) ||
          (this.filters.type && this.filters.type.type) ||
          (this.filters.third_country_contract_type && this.filters.third_country_contract_type.value) ||
          (this.filters.vendor && this.filters.vendor.id) ||
          (this.filters.document_category && this.filters.document_category.category) ||
          (this.filters.measure && this.filters.measure.id) ||
          (this.filters.number_type && this.filters.number_type.type) ||
          (this.filters.number_value && this.filters.number_value.length) ||
          (this.filters.dropdown_value && this.filters.dropdown_value.length) ||
          (this.filters.processingRole && this.filters.processingRole.value) ||
          (this.filters.dataProcessorAgreement && this.filters.dataProcessorAgreement.value) ||
          this.filters.parent ||
          this.filters.third_country_transfer ||
          this.filters.only_data_processors
        ) {
          this.handleFilterButton(true)
        }

        let params = {
          page: pageNumber,
          per_page: perPageNumber,
          sort: this.selectedSort !== '' ? this.selectedSort : undefined,
          order: this.selectedDirection !== '' ? this.selectedDirection : undefined,
          keyword: this.filters.keywordValue !== '' ? this.filters.keywordValue : undefined,
          number_value: this.filters.number_value !== '' ? this.filters.number_value : undefined,
          date_from: this.filters.date_from ? this.filters.date_from : undefined,
          date_to: this.filters.date_to ? this.filters.date_to : undefined,
          number_type: this.filters.number_type ? this.filters.number_type.type : undefined,
          type: this.filters.type ? this.filters.type.type : undefined,
          measure: this.filters.measure ? this.filters.measure.id : undefined,
          vendor: this.filters.vendor ? this.filters.vendor.id : undefined,
          document_category: this.filters.document_category ? this.filters.document_category.category : undefined,
          third_country_contract_type: this.filters.third_country_contract_type ? this.filters.third_country_contract_type.value : undefined,
          processing_role: this.filters.processingRole ? this.filters.processingRole.value : undefined,
          data_processor_agreement: this.filters.dataProcessorAgreement ? this.filters.dataProcessorAgreement.value : undefined,
          owners: formOwners ? formOwners : undefined,
          tags: formTags ? formTags : undefined,
          third_country_transfer: this.filters.third_country_transfer ? this.filters.third_country_transfer : undefined,
          only_data_processors: this.filters.only_data_processors ? this.filters.only_data_processors : undefined,
          parent: this.getClient.parent ? 1 : undefined,
          client: this.getClient.slug
        }

        if (this.filters.measure && this.filters.measure.id && this.filters.measure.value == 'dropdown' && this.filters.measure.type == 'Yes/No') {
          if (this.filters.dropdown_value && this.filters.dropdown_value.value) {
            params.dropdown_value = this.filters.dropdown_value.value;
          }
        } else {
          if (this.filters.dropdown_value != '') {
            params.dropdown_value = this.filters.dropdown_value;
          }
        }

        piincHttp.get('third-parties', { params: params }).then(function(response) {
          self.loading = false;
          self.tableItems = response.data.items;
          // Set number of total items
          self.totalRows = response.data.total_count;

          if (!self.tableItems.length && roleCheck('Member')) {
            self.goToHome()
          }
        }, function() {});
      },
      getUniqueCategories(object) {
        let categories = []
        // Loop through all data objects in third party
        if (object.data_objects && object.data_objects.length) {
          _.forEach(object.data_objects, function (item) {
            categories.push(item.classification)
          })
          // Return unique array of categories in data objects
          return _.uniq(categories);
        }
      },
      pageChanged(page) {
        this.loadContent(page)
      },
      perPageChanged(perPage) {
        let self = this;
        // Load content when page changed listener is triggered
        this.loadContent(1, perPage.value)
        self.selectedPerPage = perPage.value
        this.$store.commit('storePerPage', this.selectedPerPage);
      },
      perPageRemoved() {
        let self = this;
        // Load content when page changed listener is triggered
        self.selectedPerPage = self.perPageOptions[0].value
        this.loadContent(1, self.selectedPerPage)
      },
      showConfirmDeleteModal(object) {
        this.itemToDelete = object
        this.$refs['delete-third-party'].show()
      },
      hideConfirmDeleteModal() {
        this.itemToDelete = {}
        this.$refs['delete-third-party'].hide()
      },
      showDataObjectsModal(object) {
        this.selectedTP = object
        this.$refs['view-data-objects'].show()
      },
      hideDataObjectsModal() {
        this.selectedTP = {}
        this.$refs['view-data-objects'].hide()
      },
      confirmDeleteTP(object) {
        let self = this;
        piincHttp.delete('third-parties/' + object.slug).then(function(response) {
          self.hideConfirmDeleteModal();
          self.loadContent(self.currentPage);
          // Trigger toastr message after modal close
          self.$toastr('success', self.$t('maintenance.third_parties.delete.success'))
        }, function() {});
      },
      loadTypes(query) {
        this.typeInput = query ? query : '';
        let self = this;
        piincHttp.get('/third-parties/types', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.types = response.data
          if (self.types && !self.types.length && !query) {
            self.types.push({type: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadMeasures(query) {
        this.typeInput = query ? query : '';
        let self = this;
        piincHttp.get('/measures', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined,
            scope: 'third_party'
          }
        }).then(function(response) {
          self.measures = response.data.items
          if (self.measures && !self.measures.length && !query) {
            self.measures.push({type: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadTags(query) {
        let self = this;
        piincHttp.get('tags', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.tags = response.data
          if (self.tags && !self.tags.length && !query) {
            self.tags.push({name_translated: self.$t('system.no_results'), $isDisabled: true})
          }
          for (var i = 0; i < self.newTags.length; i++) {
            self.tags.push(self.newTags[i]);
          }
        }, function() {});
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      addTag(newTag) {
        const tag = {
          name: newTag,
          translatable: newTag,
          name_translated: newTag
        }
        this.selectedTags.push(tag)
        this.newTags.push(tag)
      },
    },
    beforeRouteLeave(to, from, next) {
      let object = {
        type: 'third_parties',
        filters: this.filters
      }
      let columnsObject = {
        type: 'third_parties',
        tableColumns: this.tableColumns
      }
      this.$store.commit('storeActiveFilters', object);
      this.$store.commit('storeTableColumns', columnsObject);
      this.$store.commit('storePerPage', this.selectedPerPage);
      next()
    },
    created () {
      if (this.getClient && this.getClient.slug) {
        if (this.getFilters.third_parties) {
          this.filters = this.getFilters.third_parties
        }
        if (this.getTableColumns.third_parties) {
          this.tableColumns = this.getTableColumns.third_parties
        }
        this.loadContent()
        this.loadTypes();
        this.loadTags();
        this.loadVendors()
        this.loadCategories()
        this.loadUsers()
      }
      this.loadGuides('third_party_list_page');
    }
  }
</script>
